<template>
  <section class="form form--narrow">
    <h2 class="form__heading">Register</h2>
    <form
      class="form__group form__group--center form__button-group form__button-group--full-width-with-margin"
      method="post"
      :action="externalLoginCallbackUrl"
    >
      <input type="hidden" name="returnUrl" :value="mode == 'popup' ? $route.fullPath : $router.returnUrl" />
      <fs-button-main
        :long="true"
        size="large"
        type="submit"
        name="provider"
        value="LinkedIn"
        title="Log in using your LinkedIn account"
        ><FsButtonIcon :small="true" :icon="svg.linkedIn" />Log in with LinkedIn</fs-button-main
      >
    </form>
    <form action="#" @submit.prevent="signUp()">
      <div class="form__group form__group--center">
        <span class="form__note-header">Or</span>
      </div>
      <div class="form__group">
        <Field
          type="email"
          name="email"
          ref="email"
          label="E-mail"
          v-model.trim="signUpData.email"
          :validation="emailValidation"
        />
        <Password
          name="password"
          ref="password"
          label="Password"
          v-model.trim="signUpData.password"
          :validation="passwordValidation"
          :toggled.sync="_toggled"
        />
      </div>
      <div class="form__group form__group--message" v-if="errors.length">
        <p class="form__message form__message--error" v-for="(error, i) in errors" :key="i">
          <span class="form__message-icon" v-html="svg.error"></span>
          {{ error }}
        </p>
      </div>
      <div class="form__button-group form__button-group--right">
        <fs-button-main :loading="loading">Register</fs-button-main>
      </div>
      <div class="form__group form__group--new-section form__group--center">
        <strong class="form__subtitle">Already have an account?</strong>
      </div>
      <div class="form__group form__group--center form__button-group--full-width-with-margin">
        <fs-button-main
          styling="outlined"
          size="large"
          :long="true"
          :event="mode == 'popup' ? '' : 'click'"
          @click.native="() => (mode == 'popup' ? $parent.open('logIn') : '')"
          :to="loginLocation"
        >
          Login
        </fs-button-main>
      </div>
    </form>
  </section>
</template>

<script>
import Field from './../components/field.vue';
import FsButtonMain from '@components/buttons/fs-button-main.vue';
import Password from './../components/password-field.vue';

import linkedIn from '../../img/in-icon.svg';
import error from '../../img/baseline-error-24px.svg';

import twoWayProperty from 'apps/public/lib/twoWayProperty';

export default {
  load({ store, route }) {
    store.head.title = `FindSourcing – Register`;
  },
  components: { FsButtonMain, Field, Password },
  props: ['mode', 'email', 'password', 'toggled'],
  data() {
    return {
      loading: false,
      svg: {
        linkedIn,
        error,
      },
      signUpData: {
        email: this.email || '',
        password: this.password || '',
        showPassword: false,
      },
      errors: [],
    };
  },
  computed: {
    _toggled: twoWayProperty('toggled'),
    externalLoginCallbackUrl() {
      let companyGuid = this.$route.query.companyGuid;

      return '/api/account/externalLogin' + (companyGuid ? '?companyGuid=' + companyGuid : '');
    },
    loginLocation() {
      let location = { name: 'login' };
      let companyGuid = this.$route.query.companyGuid;

      if (companyGuid) location.query = { companyGuid };

      return location;
    },
  },
  methods: {
    emailValidation(val) {
      if (val === '') return 'E-mail is required';
    },
    passwordValidation(val) {
      if (val === '') return 'Password is required and must be at least 6 characters!';
      if (val.length < 6 || val.length > 100) return 'Password must be at least 6 and at max 100 characters!';
    },
    signUp() {
      this.loading = true;
      this.errors = [];

      Promise.all([this.$refs.email.validates(), this.$refs.password.validates()])
        .then((validations) => {
          if (validations.filter((validation) => !validation).length) {
            this.loading = false;
            return;
          }

          return this.$store.user
            .signUp(this.signUpData, this.$route.query.companyGuid)
            .then(({ status, emailErrors, passwordErrors, otherErrors }) => {
              if (status === 2 || status === 0) {
                this.loading = false;

                (emailErrors || []).forEach((error) => this.$refs.email.addError(error));
                (passwordErrors || []).forEach((error) => this.$refs.password.addError(error));

                if ((otherErrors || []).length) this.errors = otherErrors;

                return;
              }

              // TODO: Move into store since it's generally applicable functionality???
              if (this.mode != 'popup') {
                this.$router.push(this.$router.returnUrl);
              } else {
                this.$parent.close('signUp');
              }
              this.$notify({
                type: 'success',
                title: 'You are successfully logged in',
                text: status === 1 ? 'Confirmation link is sent to your email' : null,
              });
            })
            .catch((error) => {
              throw new Error(error);
            });
        })
        .catch((error) => {
          this.errors.push('Unknown error, please try again!');
          this.loading = false;
        });
    },
  },
  watch: {
    'signUpData.email': function () {
      this.$emit('update:email', this.signUpData.email);
    },
    'signUpData.password': function () {
      this.$emit('update:password', this.signUpData.password);
    },
  },
  mounted() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (e) {}
  },
  beforeDestroy() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (e) {}
  },
};
</script>
